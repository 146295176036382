<template>
  <div class="dota">
    <div v-if="bianjiopens" class="bbbc">
      <div class="boxs">
        <div class="ul">
          <div v-for="(item, index) in touxiang">
            <div @click="changeToux(index)">
              <img
                :src="item.images"
                :class="{ selectToux: index == selectToux }"
                alt=""
              />
            </div>
          </div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <div class="boxs_bottom">
          <span @click="bianjiopens = false">取消</span>
          <span @click="changePhoto">确认</span>
        </div>
      </div>
    </div>
    <div class="dota-warp">
      <img class="topimg" src="../assets/img/my/home_cell_line_1.png" alt="" />
      <div class="flexbox">
        <div class="flex-left">
          <div class="fl-top">
            <div class="touxiang">
              <img :src="Data.img" alt="" />
              <div class="bianji" @click="bianjiopen">编辑头像</div>
            </div>
            <div>
              <span>{{ Data.name }}</span>
              <span>Lv.{{ Data.grade }}</span>
            </div>
          </div>
          <div class="fl-center">
            <div class="moneny">
              <img src="../assets/img/money.png" alt="" />
              <div>
                <span>钻石余额</span>
                <span>{{ Data.total_amount }}</span>
              </div>
            </div>
            <div class="moneny">
              <img src="../assets/img/masonry.png" alt="" />
              <div>
                <span>晶石余额</span>
                <span>{{ Data.masonry }}</span>
              </div>
            </div>
          </div>
          <div class="fl-button">
            <span @click="menuClick(4)">充值</span>
            <span @click="menuClick(6)">CDK兑换</span>
          </div>
          <div class="fl-group">
            <div
              :class="{ select: item.select }"
              v-for="(item, index) in menu"
              v-if="item.is"
              @click="menuClick(index)"
            >
              <img :src="item.img" alt="" />
              <span>{{ item.name }}</span>
            </div>
          </div>
          <div class="fl-tuichu" @click="nextLogin">退出登录</div>
        </div>
        <div class="flex-right">
          <MyKucun
            v-show="menu[0]['select']"
            :type="0"
            ref="MyKucun0"
          ></MyKucun>
          <MyKucun
            v-show="menu[1]['select']"
            :type="1"
            ref="MyKucun1"
          ></MyKucun>
          <MyKucun
            v-show="menu[2]['select']"
            :type="2"
            ref="MyKucun2"
          ></MyKucun>
          <MyJiLu v-show="menu[3]['select']"></MyJiLu>
          <MyChongZhi v-show="menu[4]['select']"></MyChongZhi>
          <MySheZhi v-show="menu[5]['select']" :Data="Data"></MySheZhi>
          <MyCdk v-show="menu[6]['select']"></MyCdk>
          <MyTuiGuang v-show="menu[7]['select']"></MyTuiGuang>
        </div>
      </div>
    </div>
    <myhomebot></myhomebot>
  </div>
</template>

<script>
import Utils from "./../assets/js/util.js";
import navheader from "@/components/navheader.vue";
import myhomebot from "@/components/my_homebot.vue";
import {
  MyKucun,
  MyJiLu,
  MyChongZhi,
  MySheZhi,
  MyCdk,
  MyTuiGuang,
} from "@/views/mes-child";
export default {
  components: {
    myhomebot,
    navheader,
    MyChongZhi,
    MyKucun,
    MyJiLu,
    MySheZhi,
    MyCdk,
    MyTuiGuang,
  },
  props: ["yidongmenu", "showNav", "openHongbao"],
  data() {
    return {
      bianjiopens: false,
      touxiang: [
        {
          img: "https://69csgoskins.oss-cn-beijing.aliyuncs.com/profile%20picture/1.png",
        },
        {
          img: "https://69csgoskins.oss-cn-beijing.aliyuncs.com/profile%20picture/2.png",
        },
        {
          img: "https://69csgoskins.oss-cn-beijing.aliyuncs.com/profile%20picture/3.png",
        },
        {
          img: "https://69csgoskins.oss-cn-beijing.aliyuncs.com/profile%20picture/4.png",
        },
        {
          img: "https://69csgoskins.oss-cn-beijing.aliyuncs.com/profile%20picture/5.png",
        },
        {
          img: "https://69csgoskins.oss-cn-beijing.aliyuncs.com/profile%20picture/6.png",
        },
        {
          img: "https://69csgoskins.oss-cn-beijing.aliyuncs.com/profile%20picture/7.png",
        },
        {
          img: "https://69csgoskins.oss-cn-beijing.aliyuncs.com/profile%20picture/8.png",
        },
        {
          img: "https://69csgoskins.oss-cn-beijing.aliyuncs.com/profile%20picture/9.png",
        },
        {
          img: "https://69csgoskins.oss-cn-beijing.aliyuncs.com/profile%20picture/10.png",
        },
        {
          img: "https://69csgoskins.oss-cn-beijing.aliyuncs.com/profile%20picture/11.png",
        },
        {
          img: "https://69csgoskins.oss-cn-beijing.aliyuncs.com/profile%20picture/12.png",
        },
        {
          img: "https://69csgoskins.oss-cn-beijing.aliyuncs.com/profile%20picture/13.png",
        },
        {
          img: "https://69csgoskins.oss-cn-beijing.aliyuncs.com/profile%20picture/14.png",
        },
        {
          img: "https://69csgoskins.oss-cn-beijing.aliyuncs.com/profile%20picture/15.png",
        },
        {
          img: "https://69csgoskins.oss-cn-beijing.aliyuncs.com/profile%20picture/16.png",
        },
        {
          img: "https://69csgoskins.oss-cn-beijing.aliyuncs.com/profile%20picture/17.png",
        },
        {
          img: "https://69csgoskins.oss-cn-beijing.aliyuncs.com/profile%20picture/18.png",
        },
        {
          img: "https://69csgoskins.oss-cn-beijing.aliyuncs.com/profile%20picture/19.png",
        },
      ],
      selectToux: 0,
      menu: [
        {
          name: "我的库存",
          img: require("../assets/img/my/icon11.png"),
          is: true,
          select: true,
        },
        {
          name: "已取回",
          img: require("../assets/img/my/icon11.png"),
          is: true,
          select: false,
        },
        {
          name: "正在取回",
          img: require("../assets/img/my/icon11.png"),
          is: true,
          select: false,
        },
        {
          name: "账户记录",
          img: require("../assets/img/my/icon11.png"),
          is: true,
          select: false,
        },
        {
          name: "充值中心",
          img: require("../assets/img/my/icon11.png"),
          is: true,
          select: false,
        },
        {
          name: "账号设置",
          img: require("../assets/img/my/icon11.png"),
          is: true,
          select: false,
        },
        {
          name: "CDK",
          img: require("../assets/img/my/icon11.png"),
          is: false,
          select: false,
        },
        {
          name: "邀请奖励",
          img: require("../assets/img/my/icon11.png"),
          is: true,
          select: false,
        },
      ],
      inviteImg: "",
      inviteName: "",
      Data: {},
      site: "",
      time: "",
      url: "",
      url1: "https://steamcommunity.com/id/me/tradeoffers/privacy#trade_offer_access_url",
      url2: "https://www.bilibili.com/video/BV15D4y1X79w/",
      urlState: true,
      checked1: false,
      checked2: false,
      flag: false,
      urlHint: "",
      timer: 0,
      updataJilu: true
    };
  },
  watch: {
    $route: function () {
      if (this.$route.query.type == "充值") {
        this.menuClick(4);
        this.$route.query.type = null;
      }
    },
  },
  methods: {
    updata() {
      this.$children[3].get()

    },
    // 获取头像数据
    getAvatar() {
      this.$axios.post('/admin/playerimages/playimglist',{page:1,pageSize:20})
      .then((res) => {
        // console.log(res,'res')
        if(res.data.status == 1) {
          let data = res.data.data.list
          data = data.sort((a,b) => b.is_default - a.is_default)
          this.touxiang = data
        }
      })
    },
    changePhoto() {
      var _this = this;
      let param = {
        imageUrl: this.touxiang[this.selectToux].images,
      };
      this.$axios
        .post("/index/User/editHeadImages", this.$qs.stringify(param))
        .then((res) => {
          var data = res.data;
          // console.log(data);
          if (data.status == "1") {
            this.$message({
              message: "修改成功",
              type: "success",
            });
            _this.Data.img = res.data.data;
            Utils.$emit("img", _this.Data.img);
            this.bianjiopens = false;
          } else {
            this.$message({
              message: "修改失败",
              type: "info",
            });
          }
        });
    },
    changeToux(index) {
      this.selectToux = index;
    },
    bianjiopen() {
      this.bianjiopens = true;
    },
    //退出登录
    nextLogin() {
      localStorage.setItem("userInfo", null);
      this.loginfalse = true;
      this.drawer = false;
      this.$store.commit("getId", {
        name: "",
        id: "",
        img: "",
        money: "",
      });
      this.$store.commit("loginOut");
      this.$router.push({
        path: `/Index`,
      });
    },
    menuClick(index) {
      var that = this;
      this.menu.forEach((e) => {
        e.select = false;
      });
      this.menu[index]["select"] = true;
      if (index == 0) {
        this.$refs.MyKucun0.getList();
      }
      if (index == 1) {
        this.$refs.MyKucun1.getList();
      }
      if (index == 2) {
        this.$refs.MyKucun2.getList();
      }
    },
    //个人中心信息
    getList() {
      let param = {};
      this.$axios
        .post("/index/User/playerInfo", this.$qs.stringify(param))
        .then((res) => {
          console.log(res.data);
          var data = res.data;
          if (data.status == "1") {
            this.Data = data.data;
            console.log(this.Data);
            this.url = this.Data.tradeUrl;
            if (this.url) {
              this.flag = true;
            }
            this.site = this.Data.last_login_info.position;
            this.time = this.Data.last_login_info.time;
            if (data.data.myInviter) {
              this.inviteImg = data.data.myInviter.img;
              this.inviteName = data.data.myInviter.name;
            }
          }
        });
    },
  },
  mounted() {
    this.getAvatar()
    this.getList();
    if (this.$route.query.type == "充值") {
      this.menuClick(4);
    }
    if (this.$route.query.type == "推广") {
      this.menuClick(6);
    }
  },
};
</script>

<style lang="less" scoped>
@media screen and (max-width: 567px) {
  .dota {
    position: relative;
    overflow: hidden;
    overflow-y: auto;
    width: 100%;
    height: 100%;
    width: 100%;
    background-image: url("../assets/img/bg-move.jpg") !important;
    background-size: 100% 100%;
    background-position: top !important;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    -webkit-background-size: cover;
    /* 兼容Webkit内核浏览器如Chrome和Safari */
    -o-background-size: cover;
    /* 兼容Opera */
    zoom: 1;
  }
}
.dota {
  width: 100%;
  height: 100%;
  background-size: 100% 100%;
  background-color: rgba(0, 0, 0, 1);

  //--------
  // position:absolute;
  // top:0;
  // left:0;
  // height:calc(100vh);
  width: 100%;
  background-image: url("../assets/img/bg-pc.jpg") !important;
  background-position: 0 -300px;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  -webkit-background-size: cover;
  /* 兼容Webkit内核浏览器如Chrome和Safari */
  -o-background-size: cover;
  /* 兼容Opera */
  zoom: 1;
}
.bbbc {
  position: fixed;
  z-index: 99999;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  // background-color: rgba(0, 0, 0, 0.7);
  display: grid;
  place-items: center;

  .boxs {
    padding: 20px;
    width: 1000px;
    background-color: rgba(40, 43, 43, 0.7843137255) !important;
    border-radius: 10px;
    border: 1px solid rgba(69, 102, 51, 0.5019607843) !important;

    .ul {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;

      .selectToux {
        cursor: pointer;
        border: 2px solid #7aba0b;
      }

      div {
        cursor: pointer;
        margin-top: 12px;
        width: 130px;

        img {
          width: 122px;
          height: 122px;
        }
      }
    }
  }

  .boxs_bottom {
    display: flex;
    justify-content: flex-end;

    span:first-child {
      border-radius: 4px;
      padding: 14px 40px;
      color: #fff;
      margin: 0 10px;
      background-color: hsla(0, 0%, 100%, 0.082);
    }

    span:last-child {
      border-radius: 4px;
      padding: 14px 40px;
      color: #fff;
      margin: 0 10px;
      background-color: #7aba0b;
    }
  }
}

.topimg {
  width: 100%;
}

.dota-warp {
  width: 1340px;
  margin: 12px auto;
  padding: 10px 0;
  min-height: 100vh;

  .flexbox {
    margin-top: 32px;
    display: flex;
    // align-items: center;
    justify-content: space-between;

    .flex-left {
      width: 240px;
      height: 717px;
      background-image: url(https://oss.88888csgo.com/web/user_bg.png);
      background-repeat: no-repeat;
      background-size: 100% 100%;
      padding: 40px 25px;
      display: flex;
      flex-direction: column;
      align-items: center;

      .fl-top {
        width: 100%;
        display: flex;

        .touxiang {
          position: relative;
          width: 70px;
          height: 70px;
          border-radius: 50%;
          margin-top: 0;
          margin-left: 0;

          img {
            width: 70px;
            height: 70px;
            border-radius: 50%;
          }

          .bianji {
            position: absolute;
            left: 0;
            right: 0;
            width: 70px;
            font-size: 12px;
            height: 70px;
            border-radius: 50%;
            color: white;
            background: rgba(0, 0, 0, 0.5);
            margin-top: 0;
            margin-left: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            display: none;
          }
        }

        .touxiang:hover {
          .bianji {
            display: flex !important;
          }
        }

        div {
          width: 100%;
          margin-left: 16px;
          display: flex;
          align-items: center;
          // margin-top: 12px;
          justify-content: space-between;

          span {
            font-size: 14px;
            font-family: "微软雅黑";
            font-weight: 700;
            color: #fff;
            line-height: 21px;
            opacity: 0.8;
          }
        }
      }

      .fl-center {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-top: 20px;

        .moneny {
          display: flex;
          width: 110px;
          height: 55px;
          background: rgba(71, 201, 243, 0.14);
          border-radius: 4px;
          display: flex;
          align-items: center;
          justify-content: center;

          span {
            margin-left: 10px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align: left;
            color: white;
            font-size: 11px !important;
          }

          span:first-child {
            font-size: 11px !important;
            font-weight: 400;
            color: #47c9f3;
            line-height: 16px;
            margin-bottom: 2px;
          }

          img {
            width: 30px;
            height: 30px;
          }
        }
      }

      .fl-button {
        margin-top: 32px;
        display: flex;
        align-items: center;

        span:last-child {
          margin-left: 12px;
        }

        span {
          width: 100px;
          height: 39px;
          font-size: 15px;
          background-repeat: no-repeat;
          font-weight: 400;
          color: #fff;
          display: flex;
          align-items: center;
          justify-content: center;
          line-height: 25px;
          background-image: url("../assets/img/my/button.png");
          background-size: 100% 100%;
        }
      }

      .fl-group {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 32px;
        width: 100%;

        div {
          display: flex;
          width: 100%;
          align-items: center;
          height: 56px;
          color: white;
          justify-content: center;

          img {
            width: 20px;
          }

          span {
            width: 68px;
            margin-left: 20px;
          }
        }

        div:hover {
          background: rgba(255, 255, 255, 0.1);
        }

        .select:hover {
          background: rgba(105, 224, 255, 0.1);
        }

        .select {
          color: #69e0ff !important;
          border-left: 2px solid #69e0ff;
        }
      }

      .fl-tuichu {
        width: 238px;
        height: 42px;
        background: rgba(71, 201, 243, 0.14);
        border-radius: 4px;
        font-size: 15px;
        font-weight: 400;
        color: #adb8ba;
        line-height: 21px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 32px;
      }

      .fl-tuichu:hover {
        background: rgba(71, 201, 243, 0.28);
      }
    }

    .flex-right {
      width: 1000px;
    }
  }
}

//分页
.roll-page {
  margin: 10px 0 0px -10px;
  // margin-bottom: 50px;
  padding-bottom: 50px;
}

.roll-page
  /deep/
  .el-pagination.is-background
  .el-pager
  li:not(.disabled).active {
  background-color: #e9b10e;
  color: #1a1c24;
}

.roll-page /deep/ .el-pagination.is-background .btn-next,
/deep/ .el-pagination.is-background .btn-prev,
/deep/ .el-pagination.is-background .el-pager li {
  background-color: #333542;
  color: #848492;
}
</style>